import { getString } from '@grandstand-web/bally-web-shared/src/newPackages/StringMgmt'
import { UpsellModalProps } from '.'
import * as Styles from './styles'

export default function UpsellModalSeasonPauseContent(props: UpsellModalProps) {
  return (
    <>
      <Styles.MarketingBlock>
        <Styles.MarketingContainer padded={true} data-theme="dark">
          <Styles.ImageContainer>
            <Styles.MarketingImage src="/lower-background-seasonal-pause.png" alt="" fill />
          </Styles.ImageContainer>
        </Styles.MarketingContainer>
      </Styles.MarketingBlock>
      <Styles.ContentBlock padded={true}>
        <Styles.ContentBlockInner>
          <Styles.ContentRow>
            <Styles.ContentTitle fontSize="3">{getString('seasonal.pause.subscription.header')}</Styles.ContentTitle>
            <Styles.ContentSubtitle fontSize="1">
              {getString('seasonal.pause.subscription.message')}
            </Styles.ContentSubtitle>
          </Styles.ContentRow>
          <Styles.ContentRow>
            <Styles.Action>
              <Styles.ButtonLink buttonSize="sm" buttonStyle="primary">
                <a href={getString('bally_sports_help.url')}>{getString('seasonal.pause.subscription.button')}</a>
              </Styles.ButtonLink>
            </Styles.Action>
          </Styles.ContentRow>
        </Styles.ContentBlockInner>
      </Styles.ContentBlock>
    </>
  )
}
