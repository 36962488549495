import { GrandstandLogo } from '@grandstand-web/bally-web-shared/src/components/GrandstandLogo'
import { UpsellMode } from '@grandstand-web/bally-web-shared/src/hooks/useUpsellLogic'
import { ApiClient } from '@grandstand-web/bally-web-shared/src/newPackages/ApiClient'
import { S, getString } from '@grandstand-web/bally-web-shared/src/newPackages/StringMgmt'
import { AnalyticServiceContext } from '@grandstand-web/bally-web-shared/src/services/analytics/AnalyticService'
import {
  ConfigServiceContext,
  useContextUnconditionally,
} from '@grandstand-web/bally-web-shared/src/services/config/ConfigService'
import { TealiumServiceContext } from '@grandstand-web/bally-web-shared/src/services/tealium/TealiumService'
import { UserServiceContext } from '@grandstand-web/bally-web-shared/src/services/user/UserService'
import { RegionTeam } from '@grandstand/presentation-models'
import { useContext, useEffect, useMemo, useRef, useState } from 'react'
import { UpsellModalProps } from '.'
import { LoadingSpinner } from '../../loading'
import { UpsellActions } from './UpsellActions'
import { UpsellForm } from './UpsellForm'
import { UpsellHeader } from './UpsellHeader'
import * as Styles from './styles'

export default function UpsellModalDefaultContent(props: UpsellModalProps) {
  const { upsell } = props
  const tealium = useContext(TealiumServiceContext)
  const analyticsService = useContext(AnalyticServiceContext)
  // contexts/hooks
  const { currentConfig } = useContextUnconditionally(ConfigServiceContext)
  const { currentUser, isInMarket } = useContext(UserServiceContext)
  const zipcode = currentUser?.profile.region.zip_code ?? '00000'
  const isModeSubscribe = upsell.mode === UpsellMode.subscribe

  // states
  const [availableTeams, setAvailableTeams] = useState<RegionTeam[]>([])

  const outOfMarket = useMemo(() => {
    return !isInMarket || !availableTeams.length
  }, [isInMarket, availableTeams])

  const loadingRef = useRef(false)
  const [ready, setReady] = useState(false)

  // make sure packages are up to date
  useEffect(() => {
    if (loadingRef.current || currentUser === undefined) {
      return
    }
    loadingRef.current = true
    const getRegion = async () => {
      const url = `${currentConfig.API.services.auth_services.region}/${zipcode}`
      const data = await ApiClient.convenientApiFetch({ url: url, method: 'GET' })
      const region = await data.json()
      setAvailableTeams(region.teams) // interstitial should always show all logos in zipcode
      loadingRef.current = false
      setReady(true)
    }
    getRegion()
  }, [currentConfig, currentUser, zipcode])

  const region = currentUser?.profile?.region

  // send analytics event
  useEffect(() => {
    const timeout = setTimeout(() => {
      if (!ready || zipcode === '00000') {
        return
      }
      analyticsService.upsellInterstitial({
        package_zipcode: zipcode,
        ecommerce: {
          items: [],
        },
      })
      tealium.upsellOpen()
    }, 250)
    return () => clearTimeout(timeout)
  }, [analyticsService, ready, zipcode, tealium])
  if (!region) {
    return null
  }
  return (
    <>
      <Styles.MarketingBlock>
        <Styles.MarketingContainer padded={true} data-theme="dark">
          <Styles.MarketingImage src="/modal-marketing-bg.png" alt="" fill />
          <Styles.MarketingContent>
            {isModeSubscribe ? (
              <Styles.MarketingLabels>
                <div>
                  <Styles.MarketingTitle fontSize="8" tabletFontSize="7" mobileFontSize="5">
                    {getString('upsell.purchase.header')}
                  </Styles.MarketingTitle>
                  <Styles.MarketingTitleAlt fontSize="8" tabletFontSize="7" mobileFontSize="5">
                    {getString('upsell.purchase.header2')}
                  </Styles.MarketingTitleAlt>
                </div>
                <Styles.MarketingTitle fontSize="3" tabletFontSize="2" mobileFontSize="2">
                  {getString('upsell.purchase.subheader')}
                </Styles.MarketingTitle>
                <Styles.MarketingUL>
                  <Styles.MarketingLI>
                    <S stringKey="upsell.purchase.bullet1" />
                  </Styles.MarketingLI>
                  <Styles.MarketingLI>
                    <S stringKey="upsell.purchase.bullet2" />
                  </Styles.MarketingLI>
                  <Styles.MarketingLI>
                    <S stringKey="upsell.purchase.bullet3" />
                  </Styles.MarketingLI>
                  <Styles.MarketingLI>
                    <S stringKey="upsell.purchase.bullet4" />
                  </Styles.MarketingLI>
                  <Styles.MarketingLI>
                    <S stringKey="upsell.purchase.bullet5" />
                  </Styles.MarketingLI>
                </Styles.MarketingUL>
              </Styles.MarketingLabels>
            ) : (
              <Styles.MarketingLabels>
                <Styles.MarketingTitle fontSize="6">{getString('upsell.mvpd.connect.cta')}</Styles.MarketingTitle>
              </Styles.MarketingLabels>
            )}
            <Styles.LogoWrapper>
              <GrandstandLogo logoStyle="horiz" />
            </Styles.LogoWrapper>
          </Styles.MarketingContent>
        </Styles.MarketingContainer>
      </Styles.MarketingBlock>
      <Styles.ContentBlock padded={true}>
        <Styles.ContentBlockInner>
          {/* show loading if teams not ready */}
          {!ready ? (
            <>
              <Styles.SpinnerStyles>
                <LoadingSpinner />
              </Styles.SpinnerStyles>
            </>
          ) : (
            <>
              <UpsellHeader availableTeams={availableTeams} outOfMarket={outOfMarket} />
              <UpsellForm />
              <UpsellActions upsell={upsell} outOfMarket={outOfMarket} />
            </>
          )}
        </Styles.ContentBlockInner>
      </Styles.ContentBlock>
    </>
  )
}
